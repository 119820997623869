/* Header */
.flow-header {
    position: fixed;
    top: 0; right: 0; left: 0;

    z-index: 9999;

    &__top {
        position: relative;
        padding: 14px 0;

        border-bottom: 1px solid $brand-light-gray-2;
        background: $white;

        z-index: 2;

        .container-fluid {
            display: flex;
            flex-direction: row;
            align-items: center;
        }    
    }

    &__logo {
        width: 46px;
        height: 47px;

        display: block;

        @include sm {
            width: 32px;
            height: 33px;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__actions {
        margin-right: auto;

        &-menu {
            display: flex;
            flex-direction: row;
            align-items: center;

            button {
                @include btn-reset();

                display: block;
                line-height: 1;

                cursor: pointer;
                transition: 0.3s all;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            &_notifications {
                margin: 0 0 0 25px;
                padding: 5px 0 5px 25px;

                border-left: 1px solid $brand-light-gray-2;

                @include sm {
                    margin: 0 0 0 20px;
                    padding: 5px 0 5px 20px;
                }

                button {
                    position: relative;

                    small {
                        width: 16px;
                        height: 16px;

                        position: absolute;
                        bottom: -4px;
                        left: -8px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        font-family: $main-font;
                        font-size: 11px;
                        font-weight: 600;
                        line-height: 13px;
                        color: $white;

                        border-radius: 50%;
                        background: $brand-red-1;
                    }

                    svg {

                        path {
                            transition: 0.3s all;
                        }
                    }

                    &:hover {

                        svg {
                            
                            path {
                                fill: darken($brand-light-gray-4, 10) !important;
                            }
                        }
                    }
                }
            }

            &_entity {
                position: relative;

                display: flex;
                flex-direction: row;
                align-items: center;

                text-align: right;

                figure {
                    margin: 0 0 0 16px;
                    z-index: 10;

                    @include sm {
                        display: none;
                    }

                    img {
                        width: 60px;
                        height: 60px;
                        object-fit: contain;
                    }
                }

                strong {
                    display: block;

                    font-size: 18px;
                    font-weight: 700;
                    line-height: 27px;
                    color: $primary;

                    @include sm {
                        max-width: 90px;

                        font-size: 17px;
                        white-space: nowrap;

                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                button {
                    font-size: 14px;
                    color: $text;
                    text-decoration: underline;
                }

                &-meta {
                    z-index: 10;
                }

                &-list {
                    display: none;

                    position: absolute;
                    top: -14px; left: -25px; right: -25px;

                    padding-top: 88px;

                    background: $white;
                    box-shadow: map-get($shadows, 'soft');

                    @include sm {
                        padding-top: 60px;
                    }
                }

                &-item {
                    padding: 14px;

                    background-color: $white;
                    cursor: pointer;
                    transition: 0.2s all;

                    &:hover {
                        background-color: $brand-light-gray-3;
                    }

                    button {
                        text-decoration: none;

                        strong {
                            color: $brand-dark;
                        }    
                    }
                }
            }
        }
    }

    &__links {
        position: relative;
        padding: 10px 0;

        border-bottom: 1px solid $brand-light-gray-2;
        background: $white;

        z-index: 1;
        transition: 0.4s all;

        &--collapsed {
            transform: translate(0, -100%);
        }

        .container-fluid {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-menu {
            position: relative;

            display: flex;
            flex-direction: row;
            justify-content: center;

            &_item {
                margin: 0 5px;

                &:first-of-type {
                    margin-right: 0;
                }

                &:nth-last-of-type(2) {
                    margin-left: 0;
                }
                
                a {
                    display: block;
                    padding: 10px 18px;

                    font-size: 18px;
                    line-height: 27px;

                    @include sm {
                        padding: 8px 10px;
                        
                        font-size: 16px;
                        line-height: 22px;
                    }
                }

                &--current {

                    a {
                        color: $primary;
                    }
                }
            }

            &_marker {
                position: absolute;
                top: 0; bottom: 0;

                border-radius: 8px;
                background-color: $brand-light-gray-3;

                z-index: -1;
            }
        }
    }
}

/* Footer */
.flow-footer {
    padding: 14px 0;
    border-top: 1px solid $brand-light-gray-2;

    @include sm {
        padding: 12px 0;
    }

    .container-fluid {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &__copyrights {
        font-size: 16px;
        line-height: 18px;
    }

    &__logo {
        width: 31px;
        height: 32px;

        display: block;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}

// General sections and components
.flow-main {
    
    .flow-section {

        &__summary {
            padding: 25px 0;
            border-bottom: 1px solid $brand-light-gray-2;

            @include sm {
                padding: 20px 0;
            }

            .container {
                display: flex;
                flex-direction: row;

                @include sm {
                    flex-direction: column;
                }
            }

            &-column {
                width: 50%;

                display: flex;
                flex-direction: column;
                justify-content: center;

                @include sm {
                    width: 100%;
                }

                &:first-of-type {
                    padding-left: 50px;
                    border-left: 1px solid $brand-light-gray-2;

                    @include sm {
                        padding: 0 0 20px;

                        border-left: none;
                        border-bottom: 1px solid $brand-light-gray-2;
                    }
                }
                
                &:last-of-type {
                    padding-right: 50px;

                    @include sm {
                        padding: 16px 0 2px;
                    }
                }

                small {
                    display: block;
                }

                &--lg {
                    padding: 10px 0 16px;

                    small {
                        margin: 0 0 48px;

                        font-size: 20px;
                        line-height: 29px;

                        @include sm {
                            margin: 0 0 10px;

                            font-size: 18px;
                            line-height: 25px;
                        }
                    }

                    bdi {
                        font-size: 130px;
                        font-weight: 700;
                        line-height: 100px;

                        text-align: right;

                        @include sm {
                            font-size: 60px;
                            line-height: 60px;
                        }
                    }

                    &.is-positive {

                        bdi {
                            color: $brand-green-1;
                        }
                    }

                    &.is-negative {
                        
                        bdi {
                            color: $brand-red-1;
                        }
                    }
                }
            }

            &-row {
                width: 100%;

                display: flex;
                flex-direction: row;
                align-items: flex-start;

                padding: 16px 0;

                @include sm {
                    padding: 15px 0 20px;
                    flex-wrap: wrap;
                }

                &:first-of-type {
                    padding-top: 0;
                    border-bottom: 1px solid $brand-light-gray-2;

                    .flow-section {

                        &__summary {

                            &-cell {

                                &:first-of-type {

                                    small {
                                        color: $brand-red-1;
                                    }
                                }
                            }
                        }
                    }
                }

                &:last-of-type {
                    padding-bottom: 0;

                    .flow-section {

                        &__summary {

                            &-cell {

                                &:first-of-type {

                                    small {
                                        color: $brand-green-1;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &-cell {
                width: 100%;
                
                &--lg {
                    max-width: 220px;
                    margin-left: auto;

                    @include sm {
                        max-width: 100%;
                        margin: 0 0 16px;
                    }

                    small {
                        margin: 0 0 2px;

                        font-size: 18px;
                        line-height: 27px;

                        @include sm {
                            margin: 0;

                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                    
                    > bdi {
                        font-size: 42px;
                        line-height: 50px;

                        @include sm {
                            font-size: 32px;
                            line-height: 40px;
                        }
                    }
                }

                &--sm {
                    width: 150px;

                    @include sm {
                        width: calc(50% - 15px);
                    }

                    &:not(:last-of-type) {
                        margin: 0 0 0 30px;
                    }

                    small {
                        margin: 0 0 6px;

                        font-size: 16px;
                        line-height: 23px;

                        @include sm {
                            margin: 0 0 2px;

                            font-size: 15px;
                        }
                    }

                    > bdi {
                        font-size: 28px;
                        line-height: 33px;

                        @include sm {
                            font-size: 24px;
                            line-height: 32px;
                        }
                    }
                }

                bdi {
                    font-weight: 700;
                }

                > bdi {
                    color: $brand-dark;
                }

                &_deposit {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    margin: 2px 0 0;

                    svg {
                        margin: 0 0 0 6px;
                    }
                    
                    span {
                        font-size: 14px;
                        line-height: 16px;
                    }

                    &.is-positive {

                        span {

                            bdi {
                                color: $brand-green-1;
                            }
                        }
                    }

                    &.is-negative {

                        span {

                            bdi {
                                color: $brand-red-1;
                            }
                        }
                    }
                }
            }
        }

        &__detailed {

            &-day {

                h5 {
                    padding: 14px 0;
                    margin: 0;

                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                    color: $brand-light-gray-5;

                    background: $brand-light-gray-3;

                    @include sm {
                        padding: 12px 0;

                        font-size: 16px;
                        line-height: 20px;
                    }
                }

                &__item {
                    padding: 15px 0;
                    border-bottom: 1px solid $brand-light-gray-2;
                    border-right: 5px solid;

                    @include sm {
                        position: relative;
                        right: 0;
                        padding: 12px 0;

                        transition: 0.3s right;
                        background: $white;
                    }

                    &[data-status="ממתין"] {
                        border-right-color: $brand-yellow-1;
                    }

                    &[data-status="שולם"] {
                        border-right-color: $brand-green-1;
                    }

                    &[data-status="בוטל"] {
                        border-right: none;

                        background: url(../images/item--canceled-bg.svg) center center repeat, $white;
                        background-size: initial;

                        .container {
                            opacity: 0.5;
                        }
                    }

                    .container {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        @include sm {
                            flex-wrap: wrap;
                        }
                    }

                    &-cell {
                        width: 100%;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        @include sm {
                            width: auto;
                            order: 9;
                        }

                        &:not(:first-of-type):not(:last-of-type) {
                            padding-left: 30px;

                            @include sm {
                                padding-left: 0;
                            }
                        }

                        &:first-of-type {
                            max-width: 40px;
                            padding-left: 25px;
                            align-items: center;

                            @include sm {
                                padding-left: 24px;
                                order: 0;
                            }
                        }

                        &:not(:nth-of-type(2)) {

                            span {
                                font-size: 16px;
                                line-height: 19px;
                            }
                        }

                        &:nth-of-type(2) {
                            max-width: 148px;

                            @include sm {
                                width: 100%;
                                max-width: 120px;

                                margin-left: 20px;
                                order: 1;
                            }

                            > bdi {
                                font-size: 20px;
                                font-weight: 700;
                                line-height: 23px;
                            }

                            &.is-positive {

                                > bdi {
                                    color: $brand-green-1;
                                }
                            }

                            &.is-negative {

                                > bdi {
                                    color: $brand-red-1;
                                }
                            }

                            small {

                                bdi {
                                    text-decoration: underline;
                                }

                                &.is-positive {

                                    > bdi {
                                        color: $brand-green-1;
                                    }
                                }
    
                                &.is-negative {
    
                                    > bdi {
                                        color: $brand-red-1;
                                    }
                                }    
                            }
                        }

                        &:not(:nth-of-type(3)) {
                            flex-shrink: 0;
                        }

                        &:nth-of-type(3) {

                            @include sm {
                                width: 100%;
                                margin: 12px 0 2px;
                            }
                            
                            span {
                                font-size: 20px;
                                line-height: 23px;

                                @include sm {
                                    font-size: 16px;
                                    line-height: 18px;
                                }
                            }
                        }

                        &:nth-of-type(4) {
                            max-width: 196px;

                            @include sm {
                                margin-left: auto;
                            }
                        }

                        &:nth-of-type(4),
                        &:nth-of-type(6) {

                            > span {

                                @include sm {
                                    font-size: 14px;
                                    line-height: 15px;
                                }
                            }
                        }

                        &:nth-of-type(5),
                        &:nth-of-type(7),
                        &:nth-of-type(8) {
                            
                            @include sm {
                                margin-left: 10px;
                            }

                            > span {

                                @include sm {
                                    font-size: 0;
                                    line-height: 1;
                                }
    
                                > span {

                                    @include sm {
                                        font-size: 16px;
                                    }    
                                }
                            }
                        }

                        &:nth-of-type(5) {
                            max-width: 152px;

                            @include sm {
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                order: 4;
                            }

                            > span {

                                > span {

                                    @include sm {
                                        position: relative;
                                        top: 1px;

                                        margin-left: 6px;
                                    }    
                                }
                            }
                        }

                        &:nth-of-type(6) {
                            max-width: 105px;
                        }

                        &:nth-of-type(7) {
                            max-width: 162px;

                            @include sm {
                                order: 2;
                            }
                        }

                        &:nth-of-type(8) {
                            max-width: 184px;

                            @include sm {
                                order: 3;
                            }
                        }

                        &:last-of-type {
                            max-width: 12px;

                            @include sm {
                                display: none;
                            }

                            button {
                                width: 12px;
                                height: 12px;

                                @include btn-reset();
                                
                                display: none;
                                cursor: pointer;

                                svg {

                                    &,
                                    path {
                                        transition: 0.2s all;
                                    }
                                }

                                &:hover {

                                    svg {
            
                                        path {
                                            fill: $brand-red-1 !important;
                                        }
                                    }
                                }
                            }
                        }

                        small {
                            font-size: 14px;
                            line-height: 16px;
                        }

                        bdi {
                            text-align: right;
                        }
                    }

                    &:hover {
                            
                        .flow-section {

                            &__detailed {

                                &-day {

                                    &__item {

                                        &-cell {

                                            &:last-of-type {

                                                button {
                                                    display: block;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &__mobile-delete {
                        display: none;
    
                        @include sm {
                            @include btn-reset();
    
                            width: 80px;
    
                            position: absolute;
                            top: 0; 
                            bottom: 0; 
                            right: -6px;

                            display: block;
                            background: $brand-red-1;

                            transition: 0.3s right;
                            z-index: -1;
                        }
                    }

                    &.remove-open {
                    
                        @include sm {
                            right: 80px;
                        }
    
                        .flow-section__detailed-day__item {
    
                            &__mobile-delete {
    
                                @include sm {
                                    right: -80px;
                                    z-index: 10;
                                    transition: none;
                                }
                            }    
                        }
                    }   
                    
                    &--transfer {
                        border-right: none;
                        border-bottom: none;
                        
                        background-color: $brand-light-gray-3;

                        .flow-section {

                            &__detailed {

                                &-day {

                                    &__item {

                                        &-cell {

                                            &:nth-of-type(3) {
                                                max-width: 100%;

                                                display: flex;
                                                flex-direction: row;
                                                align-items: center;
                                                justify-content: flex-start;

                                                svg {
                                                    margin-left: 10px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Loader */
.flow-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    background-color: $white;

    &--general {
        position: fixed;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: 9999;

        svg {
            width: 80px;
            height: 80px;

            @include sm {
                width: 60px;
                height: 60px;
            }        
        }
    }

    svg {

        > g {

            > circle {
                animation: blink-animation 0.4s linear infinite;
                -webkit-animation: blink-animation 0.4s linear infinite;
            }
        }
    }
}