@charset "UTF-8";
@keyframes blink-animation {
  to {
    opacity: 0;
  }
}
@-webkit-keyframes blink-animation {
  to {
    opacity: 0;
  }
}
* {
  box-sizing: border-box;
}

html, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding-top: 157px;
  direction: rtl;
  font-family: "Almoni Neue DL 4.0 AAA", sans-serif;
  background-color: #fff;
}
@media only screen and (max-width: 1024px) {
  body {
    padding-top: 129px;
  }
}
body.is-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
body,
body p {
  color: #95b3b4;
}

.container,
.container-fluid {
  width: 100%;
}

.container {
  max-width: 1560px;
  padding: 0 60px;
  margin: auto;
}
@media only screen and (max-width: 1024px) {
  .container {
    padding: 0 16px;
  }
}

.container-fluid {
  padding: 0 25px;
}
@media only screen and (max-width: 1024px) {
  .container-fluid {
    padding: 0 16px;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

img {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

input, input:hover, input:focus, input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active,
select,
select:hover,
select:focus,
select:active {
  outline: none !important;
  box-shadow: none !important;
}

a,
button {
  font-family: "Almoni Neue DL 4.0 AAA", sans-serif;
}
a:hover, a:focus, a:active,
button:hover,
button:focus,
button:active {
  outline: none !important;
  box-shadow: none !important;
}

input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

#g_a11y_announcement {
  right: 0 !important;
  left: 0 !important;
}

/* Header */
.flow-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
}
.flow-header__top {
  position: relative;
  padding: 14px 0;
  border-bottom: 1px solid #ededef;
  background: #fff;
  z-index: 2;
}
.flow-header__top .container-fluid {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flow-header__logo {
  width: 46px;
  height: 47px;
  display: block;
}
@media only screen and (max-width: 1024px) {
  .flow-header__logo {
    width: 32px;
    height: 33px;
  }
}
.flow-header__logo svg {
  width: 100%;
  height: 100%;
}
.flow-header__actions {
  margin-right: auto;
}
.flow-header__actions-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flow-header__actions-menu button {
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  display: block;
  line-height: 1;
  cursor: pointer;
  transition: 0.3s all;
}
.flow-header__actions-menu button svg {
  width: 100%;
  height: 100%;
}
.flow-header__actions-menu_notifications {
  margin: 0 0 0 25px;
  padding: 5px 0 5px 25px;
  border-left: 1px solid #ededef;
}
@media only screen and (max-width: 1024px) {
  .flow-header__actions-menu_notifications {
    margin: 0 0 0 20px;
    padding: 5px 0 5px 20px;
  }
}
.flow-header__actions-menu_notifications button {
  position: relative;
}
.flow-header__actions-menu_notifications button small {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: -4px;
  left: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Almoni Neue DL 4.0 AAA", sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  color: #fff;
  border-radius: 50%;
  background: #eb596e;
}
.flow-header__actions-menu_notifications button svg path {
  transition: 0.3s all;
}
.flow-header__actions-menu_notifications button:hover svg path {
  fill: #9bb5b7 !important;
}
.flow-header__actions-menu_entity {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: right;
}
.flow-header__actions-menu_entity figure {
  margin: 0 0 0 16px;
  z-index: 10;
}
@media only screen and (max-width: 1024px) {
  .flow-header__actions-menu_entity figure {
    display: none;
  }
}
.flow-header__actions-menu_entity figure img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.flow-header__actions-menu_entity strong {
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  color: #59c1bd;
}
@media only screen and (max-width: 1024px) {
  .flow-header__actions-menu_entity strong {
    max-width: 90px;
    font-size: 17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.flow-header__actions-menu_entity button {
  font-size: 14px;
  color: #95b3b4;
  text-decoration: underline;
}
.flow-header__actions-menu_entity-meta {
  z-index: 10;
}
.flow-header__actions-menu_entity-list {
  display: none;
  position: absolute;
  top: -14px;
  left: -25px;
  right: -25px;
  padding-top: 88px;
  background: #fff;
  box-shadow: 0px 5px 15px rgba(21, 23, 80, 0.05);
}
@media only screen and (max-width: 1024px) {
  .flow-header__actions-menu_entity-list {
    padding-top: 60px;
  }
}
.flow-header__actions-menu_entity-item {
  padding: 14px;
  background-color: #fff;
  cursor: pointer;
  transition: 0.2s all;
}
.flow-header__actions-menu_entity-item:hover {
  background-color: #f5fbfc;
}
.flow-header__actions-menu_entity-item button {
  text-decoration: none;
}
.flow-header__actions-menu_entity-item button strong {
  color: #222;
}
.flow-header__links {
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid #ededef;
  background: #fff;
  z-index: 1;
  transition: 0.4s all;
}
.flow-header__links--collapsed {
  transform: translate(0, -100%);
}
.flow-header__links .container-fluid {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flow-header__links-menu {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.flow-header__links-menu_item {
  margin: 0 5px;
}
.flow-header__links-menu_item:first-of-type {
  margin-right: 0;
}
.flow-header__links-menu_item:nth-last-of-type(2) {
  margin-left: 0;
}
.flow-header__links-menu_item a {
  display: block;
  padding: 10px 18px;
  font-size: 18px;
  line-height: 27px;
}
@media only screen and (max-width: 1024px) {
  .flow-header__links-menu_item a {
    padding: 8px 10px;
    font-size: 16px;
    line-height: 22px;
  }
}
.flow-header__links-menu_item--current a {
  color: #59c1bd;
}
.flow-header__links-menu_marker {
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 8px;
  background-color: #f5fbfc;
  z-index: -1;
}

/* Footer */
.flow-footer {
  padding: 14px 0;
  border-top: 1px solid #ededef;
}
@media only screen and (max-width: 1024px) {
  .flow-footer {
    padding: 12px 0;
  }
}
.flow-footer .container-fluid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.flow-footer__copyrights {
  font-size: 16px;
  line-height: 18px;
}
.flow-footer__logo {
  width: 31px;
  height: 32px;
  display: block;
}
.flow-footer__logo svg {
  width: 100%;
  height: 100%;
}

.flow-main .flow-section__summary {
  padding: 25px 0;
  border-bottom: 1px solid #ededef;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__summary {
    padding: 20px 0;
  }
}
.flow-main .flow-section__summary .container {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__summary .container {
    flex-direction: column;
  }
}
.flow-main .flow-section__summary-column {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__summary-column {
    width: 100%;
  }
}
.flow-main .flow-section__summary-column:first-of-type {
  padding-left: 50px;
  border-left: 1px solid #ededef;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__summary-column:first-of-type {
    padding: 0 0 20px;
    border-left: none;
    border-bottom: 1px solid #ededef;
  }
}
.flow-main .flow-section__summary-column:last-of-type {
  padding-right: 50px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__summary-column:last-of-type {
    padding: 16px 0 2px;
  }
}
.flow-main .flow-section__summary-column small {
  display: block;
}
.flow-main .flow-section__summary-column--lg {
  padding: 10px 0 16px;
}
.flow-main .flow-section__summary-column--lg small {
  margin: 0 0 48px;
  font-size: 20px;
  line-height: 29px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__summary-column--lg small {
    margin: 0 0 10px;
    font-size: 18px;
    line-height: 25px;
  }
}
.flow-main .flow-section__summary-column--lg bdi {
  font-size: 130px;
  font-weight: 700;
  line-height: 100px;
  text-align: right;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__summary-column--lg bdi {
    font-size: 60px;
    line-height: 60px;
  }
}
.flow-main .flow-section__summary-column--lg.is-positive bdi {
  color: #00af91;
}
.flow-main .flow-section__summary-column--lg.is-negative bdi {
  color: #eb596e;
}
.flow-main .flow-section__summary-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 0;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__summary-row {
    padding: 15px 0 20px;
    flex-wrap: wrap;
  }
}
.flow-main .flow-section__summary-row:first-of-type {
  padding-top: 0;
  border-bottom: 1px solid #ededef;
}
.flow-main .flow-section__summary-row:first-of-type .flow-section__summary-cell:first-of-type small {
  color: #eb596e;
}
.flow-main .flow-section__summary-row:last-of-type {
  padding-bottom: 0;
}
.flow-main .flow-section__summary-row:last-of-type .flow-section__summary-cell:first-of-type small {
  color: #00af91;
}
.flow-main .flow-section__summary-cell {
  width: 100%;
}
.flow-main .flow-section__summary-cell--lg {
  max-width: 220px;
  margin-left: auto;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__summary-cell--lg {
    max-width: 100%;
    margin: 0 0 16px;
  }
}
.flow-main .flow-section__summary-cell--lg small {
  margin: 0 0 2px;
  font-size: 18px;
  line-height: 27px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__summary-cell--lg small {
    margin: 0;
    font-size: 16px;
    line-height: 25px;
  }
}
.flow-main .flow-section__summary-cell--lg > bdi {
  font-size: 42px;
  line-height: 50px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__summary-cell--lg > bdi {
    font-size: 32px;
    line-height: 40px;
  }
}
.flow-main .flow-section__summary-cell--sm {
  width: 150px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__summary-cell--sm {
    width: calc(50% - 15px);
  }
}
.flow-main .flow-section__summary-cell--sm:not(:last-of-type) {
  margin: 0 0 0 30px;
}
.flow-main .flow-section__summary-cell--sm small {
  margin: 0 0 6px;
  font-size: 16px;
  line-height: 23px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__summary-cell--sm small {
    margin: 0 0 2px;
    font-size: 15px;
  }
}
.flow-main .flow-section__summary-cell--sm > bdi {
  font-size: 28px;
  line-height: 33px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__summary-cell--sm > bdi {
    font-size: 24px;
    line-height: 32px;
  }
}
.flow-main .flow-section__summary-cell bdi {
  font-weight: 700;
}
.flow-main .flow-section__summary-cell > bdi {
  color: #222;
}
.flow-main .flow-section__summary-cell_deposit {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2px 0 0;
}
.flow-main .flow-section__summary-cell_deposit svg {
  margin: 0 0 0 6px;
}
.flow-main .flow-section__summary-cell_deposit span {
  font-size: 14px;
  line-height: 16px;
}
.flow-main .flow-section__summary-cell_deposit.is-positive span bdi {
  color: #00af91;
}
.flow-main .flow-section__summary-cell_deposit.is-negative span bdi {
  color: #eb596e;
}
.flow-main .flow-section__detailed-day h5 {
  padding: 14px 0;
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: #a8c0c1;
  background: #f5fbfc;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day h5 {
    padding: 12px 0;
    font-size: 16px;
    line-height: 20px;
  }
}
.flow-main .flow-section__detailed-day__item {
  padding: 15px 0;
  border-bottom: 1px solid #ededef;
  border-right: 5px solid;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item {
    position: relative;
    right: 0;
    padding: 12px 0;
    transition: 0.3s right;
    background: #fff;
  }
}
.flow-main .flow-section__detailed-day__item[data-status=ממתין] {
  border-right-color: #ffbf00;
}
.flow-main .flow-section__detailed-day__item[data-status=שולם] {
  border-right-color: #00af91;
}
.flow-main .flow-section__detailed-day__item[data-status=בוטל] {
  border-right: none;
  background: url(../images/item--canceled-bg.svg) center center repeat, #fff;
  background-size: initial;
}
.flow-main .flow-section__detailed-day__item[data-status=בוטל] .container {
  opacity: 0.5;
}
.flow-main .flow-section__detailed-day__item .container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item .container {
    flex-wrap: wrap;
  }
}
.flow-main .flow-section__detailed-day__item-cell {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell {
    width: auto;
    order: 9;
  }
}
.flow-main .flow-section__detailed-day__item-cell:not(:first-of-type):not(:last-of-type) {
  padding-left: 30px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:not(:first-of-type):not(:last-of-type) {
    padding-left: 0;
  }
}
.flow-main .flow-section__detailed-day__item-cell:first-of-type {
  max-width: 40px;
  padding-left: 25px;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:first-of-type {
    padding-left: 24px;
    order: 0;
  }
}
.flow-main .flow-section__detailed-day__item-cell:not(:nth-of-type(2)) span {
  font-size: 16px;
  line-height: 19px;
}
.flow-main .flow-section__detailed-day__item-cell:nth-of-type(2) {
  max-width: 148px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:nth-of-type(2) {
    width: 100%;
    max-width: 120px;
    margin-left: 20px;
    order: 1;
  }
}
.flow-main .flow-section__detailed-day__item-cell:nth-of-type(2) > bdi {
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
}
.flow-main .flow-section__detailed-day__item-cell:nth-of-type(2).is-positive > bdi {
  color: #00af91;
}
.flow-main .flow-section__detailed-day__item-cell:nth-of-type(2).is-negative > bdi {
  color: #eb596e;
}
.flow-main .flow-section__detailed-day__item-cell:nth-of-type(2) small bdi {
  text-decoration: underline;
}
.flow-main .flow-section__detailed-day__item-cell:nth-of-type(2) small.is-positive > bdi {
  color: #00af91;
}
.flow-main .flow-section__detailed-day__item-cell:nth-of-type(2) small.is-negative > bdi {
  color: #eb596e;
}
.flow-main .flow-section__detailed-day__item-cell:not(:nth-of-type(3)) {
  flex-shrink: 0;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:nth-of-type(3) {
    width: 100%;
    margin: 12px 0 2px;
  }
}
.flow-main .flow-section__detailed-day__item-cell:nth-of-type(3) span {
  font-size: 20px;
  line-height: 23px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:nth-of-type(3) span {
    font-size: 16px;
    line-height: 18px;
  }
}
.flow-main .flow-section__detailed-day__item-cell:nth-of-type(4) {
  max-width: 196px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:nth-of-type(4) {
    margin-left: auto;
  }
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:nth-of-type(4) > span, .flow-main .flow-section__detailed-day__item-cell:nth-of-type(6) > span {
    font-size: 14px;
    line-height: 15px;
  }
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:nth-of-type(5), .flow-main .flow-section__detailed-day__item-cell:nth-of-type(7), .flow-main .flow-section__detailed-day__item-cell:nth-of-type(8) {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:nth-of-type(5) > span, .flow-main .flow-section__detailed-day__item-cell:nth-of-type(7) > span, .flow-main .flow-section__detailed-day__item-cell:nth-of-type(8) > span {
    font-size: 0;
    line-height: 1;
  }
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:nth-of-type(5) > span > span, .flow-main .flow-section__detailed-day__item-cell:nth-of-type(7) > span > span, .flow-main .flow-section__detailed-day__item-cell:nth-of-type(8) > span > span {
    font-size: 16px;
  }
}
.flow-main .flow-section__detailed-day__item-cell:nth-of-type(5) {
  max-width: 152px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:nth-of-type(5) {
    display: flex;
    flex-direction: row;
    align-items: center;
    order: 4;
  }
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:nth-of-type(5) > span > span {
    position: relative;
    top: 1px;
    margin-left: 6px;
  }
}
.flow-main .flow-section__detailed-day__item-cell:nth-of-type(6) {
  max-width: 105px;
}
.flow-main .flow-section__detailed-day__item-cell:nth-of-type(7) {
  max-width: 162px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:nth-of-type(7) {
    order: 2;
  }
}
.flow-main .flow-section__detailed-day__item-cell:nth-of-type(8) {
  max-width: 184px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:nth-of-type(8) {
    order: 3;
  }
}
.flow-main .flow-section__detailed-day__item-cell:last-of-type {
  max-width: 12px;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item-cell:last-of-type {
    display: none;
  }
}
.flow-main .flow-section__detailed-day__item-cell:last-of-type button {
  width: 12px;
  height: 12px;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  display: none;
  cursor: pointer;
}
.flow-main .flow-section__detailed-day__item-cell:last-of-type button svg,
.flow-main .flow-section__detailed-day__item-cell:last-of-type button svg path {
  transition: 0.2s all;
}
.flow-main .flow-section__detailed-day__item-cell:last-of-type button:hover svg path {
  fill: #eb596e !important;
}
.flow-main .flow-section__detailed-day__item-cell small {
  font-size: 14px;
  line-height: 16px;
}
.flow-main .flow-section__detailed-day__item-cell bdi {
  text-align: right;
}
.flow-main .flow-section__detailed-day__item:hover .flow-section__detailed-day__item-cell:last-of-type button {
  display: block;
}
.flow-main .flow-section__detailed-day__item__mobile-delete {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item__mobile-delete {
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background: none;
    appearance: none;
    -webkit-appearance: none;
    width: 80px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -6px;
    display: block;
    background: #eb596e;
    transition: 0.3s right;
    z-index: -1;
  }
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item.remove-open {
    right: 80px;
  }
}
@media only screen and (max-width: 1024px) {
  .flow-main .flow-section__detailed-day__item.remove-open .flow-section__detailed-day__item__mobile-delete {
    right: -80px;
    z-index: 10;
    transition: none;
  }
}
.flow-main .flow-section__detailed-day__item--transfer {
  border-right: none;
  border-bottom: none;
  background-color: #f5fbfc;
}
.flow-main .flow-section__detailed-day__item--transfer .flow-section__detailed-day__item-cell:nth-of-type(3) {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.flow-main .flow-section__detailed-day__item--transfer .flow-section__detailed-day__item-cell:nth-of-type(3) svg {
  margin-left: 10px;
}

/* Loader */
.flow-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: #fff;
}
.flow-loader--general {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}
.flow-loader--general svg {
  width: 80px;
  height: 80px;
}
@media only screen and (max-width: 1024px) {
  .flow-loader--general svg {
    width: 60px;
    height: 60px;
  }
}
.flow-loader svg > g > circle {
  animation: blink-animation 0.4s linear infinite;
  -webkit-animation: blink-animation 0.4s linear infinite;
}