// Fonts
$main-font: 'Almoni Neue DL 4.0 AAA', sans-serif;

// Colors
$white: #fff;
$black: #000;

$brand-dark: #222;

$brand-light-gray-1: #95b3b4;
$brand-light-gray-2: #ededef;
$brand-light-gray-3: #f5fbfc;
$brand-light-gray-4: #b9cbcc;
$brand-light-gray-5: #a8c0c1;

$brand-turquoise-1: #59c1bd;

$brand-green-1: #00af91;

$brand-yellow-1: #ffbf00;

$brand-red-1: #eb596e;
$brand-red-2: #dd5353;

$primary: $brand-turquoise-1;
$text: $brand-light-gray-1;

// Shadows and gradients
$shadows: (
    'soft': 0px 5px 15px rgba(21, 23, 80, 0.05)
);

$gradients: (

);

// Keyframe animations
@keyframes blink-animation {

    to {
        opacity: 0;
    }
}

@-webkit-keyframes blink-animation {

    to {
        opacity: 0;
    }
}